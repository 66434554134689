import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Typed from 'react-typed'


import Layout from '../components/Layout'
import ProjectRoll from '../components/ProjectRoll'

export const IndexPageTemplate = ({
  title,
  titles,
}) => {

  return (
    <section className="section">
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="title-wrapper">
              <h1 className="title-text">
                {title}
                <br/>
                <Typed strings={titles} typeSpeed={75} loop style={{color: '#0af'}} />
              </h1>
            </div>
            <div style={{height: '2em'}} />
            <p><a style={{margin: '0 9px 0 9px', color: '#0af'}} href="/tags">All Capabilities &rarr;</a></p>
            <div style={{height: '2em'}} />
            <p><a id="work" href="/#work" style={{marginTop: "2rem", margin: '0 9px 0 9px', color: '#0af'}}>View Work &darr;</a></p>
            <br/>
            <ProjectRoll />
            <br/>
          </div>
        </div>
      </div>
    </section>
  )
}

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  titles: PropTypes.array,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        titles={frontmatter.titles}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
query IndexPageTemplate {
  markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
      frontmatter {
        title
        titles
      }
    }
  }
`
